import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,

  Link,
} from "@material-ui/core";
// import TextField from "@material-ui/core/TextField";
import {} from "react-feather";
import moment from "moment";
// import TwitterIcon from "@material-ui/icons/Twitter";
// import TelegramIcon from "@material-ui/icons/Telegram";
// import InstagramIcon from "@material-ui/icons/Instagram";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    background: "rgb(0 0 0 / 72%)",
    position: "relative",
    padding: "10px 0px",
    // "&"
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    "& .rightsection": {
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
    "& h5": {
      fontWeight: "bold",
      fontSize: "16px",
      letterSpacing: "2px",
      textTransform: "uppercase",
      color: "#2f086a",
    },
    "& ul": {
      paddingLeft: "0",
    },
    "& p": {
      marginBottom: "0px",
      marginTop: "10px",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "18px",
      color: "#000000",
    },
  },
  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },
  ListItem: {
    paddingLeft: "0px",
  },
  borderBottmo: {
    overflow: "hidden",
    background: "#000",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  signupBtn: {
    color: "#fff",
    display: "flex",
    fontSize: "16px",
    fontWeight: "bold",
    height: "45px",
    minWidth: "100px",
    borderRadius: "50px",
    position: "absolute",
    top: "5px",
    right: "5px",
    boxShadow:
      "0px 8px 24px rgba(38, 50, 56, 0.1), 0px 16px 32px rgba(38, 50, 56, 0.08)",
    lineHeight: "36px",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: " 1px",
    background: "#040405",
    "&:hover": {
      background: "#040405",
      color: "#fff",
    },
  },
  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
  },
  footerBgImg: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: "1",
  },
  textFild: {
    position: "relative",

    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#000",
      minWidth: "90px",
      fontSize: "18px",
      fontWeight: "700",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#000",
      },
    },
  },
  circuleBox: {
    width: "35px",
    height: "35px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EB4D00",
    borderRadius: "50px",
    "& img": {
      width: "13px",
    },
  },
  circuleflex: {
    display: "flex",
  },
  textright: {
    "& p": {
      color: "#9f9d9d",
      fontSize: "14px",
    },
  },
  gridflex: {
    display: "flex",
    justifyContent: " space-between",
    alignItems: "center",
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  var year1 = moment().format("YYYY");
  return (
    <>
      <Box
        className={classes.footerSection}
        style={{
          backgroundColor: "#000",
        }}
      >
        <Box>
          <Container maxWidth="lg">
            <Grid
              container
              // justify="space-around"
              spacing={1}
              className={classes.gridflex}
            >
              <Grid item xs={12} sm={4} md={3}>
                <img alt="" src="images/logo.png" style={{ width: "100px" }} />
              </Grid>

              <Grid item xs={12} sm={4} md={5} align="center">
                <Box className={classes.textright}>
                  <Typography variant="body1">© {year1} | MeglaDoge</Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={3} className="rightsection">
                <Link
                  href="https://www.facebook.com/MeglaDoge-110198944829776"
                  target="_blank"
                  target="_blank"
                >
                  <img src="images/fb.svg" />
                </Link>
                {/* <Link href="https://twitter.com/" target="_blank">
                  <img src="images/twitter.svg"/>
                </Link> */}
                <Link href="https://t.me/megladoge" target="_blank">
                  <img src="images/teligram.svg" />
                </Link>
                <Link
                  href="https://www.instagram.com/MeglaDogeOfficial/"
                  target="_blank"
                >
                  <img src="images/insta.svg" />
                </Link>
                {/* <Box className={classes.circuleflex}>
                  <Box className={classes.circuleBox}>
                    <img src="images/facebook.png" alt="" />
                  </Box>
                  &nbsp;&nbsp;&nbsp;
                  <Box className={classes.circuleBox}>
                    <img src="images/twitter.png" alt="" />
                    <TwitterIcon style={{ color: "#fff" }} />
                  </Box>
                  &nbsp;&nbsp;&nbsp;
                  <Box className={classes.circuleBox}>
                    <img src="images/telegram.png" alt="" />
                    <TelegramIcon style={{ color: "#fff" }} />
                  </Box>
                  &nbsp;&nbsp;&nbsp;
                  <Box className={classes.circuleBox}>
                    <img src="images/instagram.png" alt="" />
                    <InstagramIcon style={{ color: "#fff" }} />
                  </Box>
                </Box> */}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}

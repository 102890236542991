const baseurl = "https://node-gaming.mobiloitte.com";
// const baseurl1 = "http://182.72.203.245:1894";
const user = `${baseurl}/api/v1/user`;
const nft = `${baseurl}/api/v1/nft`;
const order = `${baseurl}/api/v1/order`;
let activity = `${baseurl}/api/v1/activity`;
let bid = `${baseurl}/api/v1/bid`;
const dashboard = `${baseurl}/api/v1/admin`;

//http://182.72.203.245:1894/api/v1/user/connectWallet

const apiConfig = {
  alldata: `${dashboard}/dashboard`,

  connectWallet: `${user}/connectWallet`,
  profile: `${user}/profile`,
  updateProfile: `${user}/updateProfile`,
  userSubscribe: `${user}/userSubscribe`,

  userOwendCount: `${user}/userOwendCount/`,
  userCreatedCount: `${user}/userCreatedCount/`,
  createReports: `${user}/createReports`,
  getUserDetails: `${user}/getUserDetails/`,
  userFollowingCount: `${user}/userFollowingCount/`,
  userFollowerCount: `${user}/userFollowerCount/`,
  userLikesCount: `${user}/userLikesCount/`,
  nftSoldCount: `${user}/nftSoldCount/`,
  followUnfollow: `${user}/followUnfollow/`,
  userBuyList: `${user}/userBuyList/`,

  listNFT: `${nft}/listNFT`,
  nft: `${nft}/nft`,
  showActivity: `${nft}/showActivity`,
  createNFT: `${nft}/createNFT`,
  likeDislikeNft: `${nft}/likeDislikeNft/`,
  sendNFT: `${nft}/sendNFT`,
  showNftHistory: `${nft}/showNftHistory`,
  stackUnstack: `${nft}/stackUnstack`,

  userOwendCount: `${user}/userOwendCount/`,
  createOrder: `${order}/createOrder`,
  likeDislikeOrder: `${order}/likeDislikeOrder/`,
  listOrder: `${order}/listOrder`,
  viewOrder: `${order}/viewOrder/`,

  allListOrder: `${order}/allListOrder`,
  editOrder: `${order}/editOrder`,
  buyOrder: `${order}/buyOrder`,
  sendOrderToUser: `${order}/sendOrderToUser`,
  cancelOrder: `${order}/cancelOrder`,
  //activity
  allListActivity: `${activity}/allListActivity`,
  listActivity: `${activity}/listActivity`,

  createBid: `${bid}/createBid`,
  hotBid: `${bid}/hotBid`,
};

export default apiConfig;

import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "./layouts/DashboardLayout";
export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/explore",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Explore/Explore")),
  },
  {
    exact: true,
    path: "/resell-nft",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/ResellNFT/ResellNftt")),
  },
  {
    exact: true,
    path: "/mint-players",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MintPage/Mint")),
  },
  {
    exact: true,
    path: "/my-items",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MyItems/MyItem")),
  },
  {
    exact: true,
    path: "/user",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/UsersView/UsersView")),
  },
  {
    exact: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Profile")),
  },
  // {
  //   exact: true,
  //   path: "/myitemtab",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/MyItemtabs/Index")),
  // },
  {
    exact: true,
    path: "/nft-detail",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NftDetails")),
  },
  {
    exact: true,
    path: "/howitworks",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/HowitWorks/How")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },
  {
    exact: true,
    path: "/collection",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NftDetails/Collection")),
  },
  {
    exact: true,
    path: "/activity",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Activity")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About")),
  },
  {
    exact: true,
    path: "/our-support",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About/Support")),
  },
  {
    exact: true,
    path: "/stake",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Stake/Index")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];

import React from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Footer from "./Footer";
import TopBar from "./TopBar";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: "#fff",
    backgroundColor: "#000",
    background:
      "radial-gradient(46.99% 29.57% at 7.14% 81.61%, rgba(255, 132, 68, 0.22) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(57.29% 31.76% at 91.96% 86.28%, rgba(9, 131, 205, 0.2) 0%, rgba(92, 45, 153, 0) 100%), radial-gradient(33.82% 43.36% at 5.24% 52.09%, rgba(91, 30, 1, 0.315) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(30.5% 34.87% at 88.84% 50.53%, rgba(9, 131, 205, 0.5) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(32.94% 42.26% at 8.54% 23.26%, rgba(91, 30, 1, 0.5) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(34.72% 23.14% at 69.46% 19.1%, rgba(9, 131, 205, 0.5) 0%, rgba(0, 0, 0, 0) 100%)",
  },
  MainLayout: {
    minHeight: "calc(100vh - 228px)",
  },
}));

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  console.log(history.location);
  return (
    <div className={classes.root}>
      <TopBar />
      <div
        style={
          history.location.pathname !== "/"
            ? { display: "block" }
            : { display: "none" }
        }
      ></div>

      <div className={classes.MainLayout}>{children}</div>
      <Footer />
    </div>
  );
};

export default MainLayout;

import { toast } from "react-toastify";

export const NetworkContextName = "NETWORK";
export const ACTIVE_NETWORK = 97;
export const GenerativeNFTContract =
  "0x9443ca45f6ed9338ec1e194a499230d1a31b8844";
// "0x6624EA61082Cb22d6f6A2197C0A9780090D21bBb";
export const MarketplaceContractAddress =
  "0x12fEAbf824c7339b88fB61690097a4DfB7D38069";
export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
export const deadAddress = "0x0000000000000000000000000000000000000000";
export const BNBTokenAddress = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";
export const TokenAddress = "0xA2032241D167DA3f9027e8E841ac657C15695838";
export const TokenAddressSymbol = "NEW";
export const stakingNFTContract = "0xB919FCD13dbD3610A370a53e441cC65f87fAC2DA";
export const coinStakeContract = "0xc64e061BfB5BA81BD19C3D82203bBdDa62389506";
export const CategoryButtons = [
  {
    name: "Art",
    // startIcon: <i className="icon-palette"></i>,
    img: "images/category/1.png",
  },
  {
    name: "Music",
    // startIcon: <i className="icon-music"></i>,
    img: "images/category/2.png",
  },
  {
    name: "Games",
    // startIcon: <i className="icon-games"></i>,
    img: "images/category/3.png",
  },
  {
    name: "NSFW",
    // startIcon: <HiOutlineBan />,
    img: "images/category/4.png",
  },
  {
    name: "Memes",
    // startIcon: <i className="icon-baby"></i>,
    img: "images/category/5.png",
  },
  {
    name: "Punks",
    // startIcon: <i className="icon-punks"></i>,
    img: "images/category/6.png",
  },
  // {
  //   name: "DeFi",
  //   startIcon: <RiMoneyDollarCircleFill />,
  // },
  // {
  //   name: "Domains",
  //   startIcon: <i className="icon-web"></i>,
  // },
  // {
  //   name: "Metaverses",
  //   startIcon: <i className="icon-camera"></i>,
  // },
];

export const CardData = [
  {
    img5: "url(./images/bgmap1.png)",
    text1: "images/Img1.png",
    text2: "#425476",
    text3: "1.1 ETH",
    text4: "0.004 ETH",
    text5: "ENDING IN",
    text6: "8h : 15m : 25s left",
  },
  {
    img5: "url(./images/bgmap2.png)",
    text1: "images/Img2.png",
    text2: "#425476",
    text3: "1.1 ETH",
    text4: "0.004 ETH",
    text5: "ENDING IN",
    text6: "8h : 15m : 25s left",
  },
  {
    img5: "url(./images/bgmap3.png)",
    text1: "images/Img3.png",
    text2: "#425476",
    text3: "1.1 ETH",
    text4: "0.004 ETH",
    text5: "ENDING IN",
    text6: "8h : 15m : 25s left",
  },
  {
    img5: "url(./images/bgmap4.png)",
    text1: "images/Img4.png",
    text2: "#425476",
    text3: "1.1 ETH",
    text4: "0.004 ETH",
    text5: "ENDING IN",
    text6: "8h : 15m : 25s left",
  },
  {
    img5: "url(./images/bgmap1.png)",
    text1: "images/Img1.png",
    text2: "#425476",
    text3: "1.1 ETH",
    text4: "0.004 ETH",
    text5: "ENDING IN",
    text6: "8h : 15m : 25s left",
  },
  {
    img5: "url(./images/bgmap2.png)",
    text1: "images/Img2.png",
    text2: "#425476",
    text3: "1.1 ETH",
    text4: "0.004 ETH",
    text5: "ENDING IN",
    text6: "8h : 15m : 25s left",
  },
  {
    img5: "url(./images/bgmap3.png)",
    text1: "images/Img3.png",
    text2: "#425476",
    text3: "1.1 ETH",
    text4: "0.004 ETH",
    text5: "ENDING IN",
    text6: "8h : 15m : 25s left",
  },
  {
    img5: "url(./images/bgmap4.png)",
    text1: "images/Img4.png",
    text2: "#425476",
    text3: "1.1 ETH",
    text4: "0.004 ETH",
    text5: "ENDING IN",
    text6: "8h : 15m : 25s left",
  },
];

export const NetworkDetails = [
  {
    chainId: "0x61",
    chainName: "Smart Chain - Testnet",
    nativeCurrency: {
      name: "Smart Chain",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
];

export const getMarketplaceContractAddress = (chianId) => {
  switch (chianId.toString()) {
    case "42":
      return MarketplaceContractAddress;
    case "97":
      return MarketplaceContractAddress;
    case "80001":
      return MarketplaceContractAddress;
    case "43113":
      return MarketplaceContractAddress;
    case "4002":
      return MarketplaceContractAddress;
  }
};

export const swichNetworkHandler = async (network) => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + parseFloat(network).toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  }
};

export const addNetworkHandler = async (network) => {
  const NetworkDetails = getNetworkDetails(network);
  try {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: NetworkDetails,
    });
  } catch (error) {
    console.log("ERROR", error);
    toast.warn(error.message);
  }
};

export const getNetworkDetails = (chianId) => {
  switch (chianId.toString()) {
    case "42":
      return [
        {
          chainId: "0x2A",
          chainName: "Kovan Test Network",
          nativeCurrency: {
            name: "Kovan Test Network",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: [
            "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          ],
          blockExplorerUrls: ["https://kovan.etherscan.io"],
        },
      ];
    case "97":
      return [
        {
          chainId: "0x61",
          chainName: "Smart Chain - Testnet",
          nativeCurrency: {
            name: "Smart Chain - Testnet",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com/"],
        },
      ];
    case "80001":
      return [
        {
          chainId: "0x13881",
          chainName: "Mumbai testnet",
          nativeCurrency: {
            name: "Mumbai testnet",
            symbol: "MATIC",
            decimals: 18,
          },
          rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
          blockExplorerUrls: ["https://explorer-mumbai.maticvigil.com/"],
        },
      ];
    case "43113":
      return [
        {
          chainId: "0xA869",
          chainName: "Avalanche Network",
          nativeCurrency: {
            name: "Avalanche Network",
            symbol: "AVAX",
            decimals: 18,
          },
          rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
          blockExplorerUrls: ["https://testnet.snowtrace.io/"],
        },
      ];
    case "4002":
      return [
        {
          chainId: "0xfa2",
          chainName: "Fantom Opera",
          nativeCurrency: {
            name: "Fantom Opera",
            symbol: "FTM",
            decimals: 18,
          },
          rpcUrls: ["https://rpc.testnet.fantom.network/"],
          blockExplorerUrls: ["https://rpc.testnet.fantom.network/"],
        },
      ];
  }
};

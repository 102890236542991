import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Avatar,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
  Typography,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { UserContext } from "src/context/User";

import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { GiCancel } from "react-icons/gi";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const headersData = [
  {
    label: "Explore",
    href: "/explore",
  },
  {
    label: "Mint",
    href: "/mint-players",
  },
  {
    label: "My Items",
    href: "/my-items",
  },
  {
    label: "Activity",
    href: "/activity",
  },
  {
    label: "Stake",
    href: "/stake",
  },
  {
    label: "How It Works",
    href: "/howitworks",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    borderRadius: 0,
    minWidth: "auto",
    color: "#fff",
    height: "31px",
    padding: "0px 7px",
    letterSpacing: "1px",
    marginLeft: "15px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&:active": {
      color: "#2ee9f6",
    },
    "&:hover": {
      color: "#2ee9f6",
    },
  },

  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "216px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "0px",
    fontSize: "25px",
    [theme.breakpoints.down("xs")]: {
      right: "0px",
    },
  },
  logoImg: {
    width: "50px",
    // height: '44.5px',
    margin: " 14px 15px 11px 26px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "42px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 1280px)": {
      padding: "7px 0",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  bgbtn: {
    color: "#fff",
    transition: "all 0.2s ease, visibility 0s",
    padding: "15px 25px",
    borderRadius: "50px",
    border: "0px solid rgb(63, 118, 82)",
    background:
      " no-repeat padding-box border-box 50%/cover scroll url(./images/btnorange.png), rgba(63, 118, 82, 0)",
    transition: "inherit",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: " 18px",
    minWidth: "150px",
    "&:hover": {
      background:
        " no-repeat padding-box border-box 50%/cover scroll url(./images/bluebtn.png), rgba(63, 118, 82, 0)",
    },
  },
  bgbtn1: {
    transition: "all 0.2s ease, visibility 0s",
    marginTop: ".7rem",
    display: "flex",
    flexDirection: "start",
    padding: "10px 25px",
    borderRadius: "50px",
    border: "0px solid rgb(63, 118, 82)",
    background:
      " no-repeat padding-box border-box 50%/cover scroll url(./images/btnorange.png), rgba(63, 118, 82, 0)",
    transition: "inherit",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: " 15px",
    minWidth: "130px",
    "&:hover": {
      background:
        " no-repeat padding-box border-box 50%/cover scroll url(./images/bluebtn.png), rgba(63, 118, 82, 0)",
    },
  },

  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #EB4D00",
    padding: "0 15px",
    background: "#EB4D00",
    borderRadius: "50px",
    height: "31px",
    "&:hover": {
      background: "fff",
      color: "#EB4D00",
    },
    "@media (max-width: 900px)": {
      marginLeft: "12px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  menubox: {
    "& ul": {
      borderRadius: 0,
      backgroundColor: "rgb(226 74 0)",
      width: "120px",
      padding: "10px",

      "& a": {
        fontSize: "15px",
      },
    },
  },
  customizedButton: {
    position: "absolute",
    top: "0px",
    right: "0px",
    color: "white",
  },
}));

export default function Header() {
  const { account, library, chainId } = useWeb3React();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    logoDrawer,
    containerHeight,
    customizedButton,
    menubox,
    mainHeader,
    bgbtn,
    bgbtn1,
    wallet,
    submenu,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const user = React.useContext(UserContext);

  const { mobileView, drawerOpen } = state;
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  // const handleToggle = (name) => {
  //   setOpen1({ ...open1, [name]: !open1[name] });
  // };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open1);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open1 === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open1;
  // }, [open1]);
  const handleClickOpen1 = async (e) => {
    if (typeof userAddress === "undefined") {
      user.connectWallet();
      // toast.success("Connected Successful");
      // seterrhandleClose1(true);
      // seterrpopup1(true);
      // seterrmsg1("Connected Successful");
    } else {
      // handleClick1(e);
    }
  };

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: "0px" }}
          >
            {/* <div className={search}>
              <div className={searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search items, collections, creators"
                classes={{
                  root: inputRoot,
                  input: inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div> */}
            {getMenuButtons()}
            {/* <Button
              ref={anchorRef.community}
              aria-controls={open1.community ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={() => handleToggle("community")}
              endIcon={<ExpandMoreIcon />}
              className={menuButton}
            >
              Community
            </Button> */}
            {!account && (
              <Button
                className={bgbtn}
                // variant="contained"
                aria-controls="simple-menu"
                aria-haspopup="true"
                // to="/login"
                // component={Link}
                onClick={user.connectWallet}
              >
                Connect
              </Button>
            )}

            {/* {account && (
              <Button
                // style={{
                //   background:
                //     "linear-gradient(124deg, #2f5995cf 18.76%, #151d2ade 43.13%, #0058f1a6 96.83%)",
                //   borderRadius: "21px",
                //   color: "#fff",
                //   border: "2px solid rgb(37 45 71)",
                // }}
                // variant="contained"
                // variant="outlined"
                aria-label='small outlined button group'
                onClick={() =>
                  user
                    .dicconectWalletFun
                    // toast.error("Successfully disconnected")
                    ()
                }
              >
                {" "}
                Disconnect{" "}
                <span style={{ color: "#eb4d00", paddingLeft: "10px" }}>
                  {account &&
                    `${account.substring(0, 4)}...${account.substring(
                      account.length - 4,
                      account.length
                    )}`}
                </span>
              </Button>
            )} */}

            {account && (
              <Box pl={1}>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick3}
                  // aria-label="delete"
                  size="small"
                  // className={classes.login}
                  style={{
                    background: "#eb4d00",
                  }}
                  // ref={anchorRef.user}
                  // aria-controls={open1.user ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  // onClick={() => handleToggle("user")}
                >
                  {" "}
                  <Avatar
                    src={
                      user?.userData?.profilePic
                        ? user?.userData?.profilePic
                        : "images/user1.png"
                    }
                  />
                </IconButton>
                {/* for test */}
                <Menu
                  id="simple-menu"
                  style={{ position: "absolute", top: "3.5%" }}
                  anchorEl={anchorEl3}
                  keepMounted
                  className={menubox}
                  open={Boolean(anchorEl3)}
                  onClose={handleClose3}
                >
                  <Link
                    to="/profile"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <MenuItem onClick={handleClose3}>Profile</MenuItem>
                  </Link>
                  <Link
                    to="/edit-profile"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <MenuItem onClick={handleClose3}>Edit Profile</MenuItem>
                  </Link>
                </Menu>
                {/*  */}
              </Box>
            )}
            <Popper
              open={open1.community}
              anchorEl={anchorRef.community.current}
              role={undefined}
              transition
              disablePortal
              className={submenu}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={(e) => handleClose2(e, "community")}
                    >
                      <MenuList
                        autoFocusItem={open1}
                        id="menu-list-grow"
                        onKeyDown={(e) => handleListKeyDown(e, "community")}
                      >
                        <MenuItem component={Link} to="/fungy-token">
                          Fungy Token
                        </MenuItem>
                        <MenuItem component={Link} to="/faq">
                          Discussion
                        </MenuItem>
                        <MenuItem>Voting</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {/* <Box display="flex" justifyContent="end">
              <GiCancel style={{ color: "red" }} />
            </Box> */}
            <IconButton
              onClick={handleDrawerClose}
              className={customizedButton}
            >
              <GiCancel />
            </IconButton>
            <img className={logoDrawer} src="images/logo.png" alt="" />
            {account && (
              <Box pl={1}>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick3}
                  // aria-label="delete"
                  size="small"
                  // className={classes.login}
                  style={{
                    background: "#eb4d00",
                  }}
                  // ref={anchorRef.user}
                  // aria-controls={open1.user ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  // onClick={() => handleToggle("user")}
                >
                  {" "}
                  <Avatar
                    src={
                      user?.userData?.profilePic
                        ? user?.userData?.profilePic
                        : "images/user1.png"
                    }
                  />
                </IconButton>
                {/* for test */}
                <Menu
                  id="simple-menu"
                  style={{ position: "absolute", top: "3.5%" }}
                  anchorEl={anchorEl3}
                  keepMounted
                  className={menubox}
                  open={Boolean(anchorEl3)}
                  onClose={handleClose3}
                >
                  <Link
                    to="/profile"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <MenuItem onClick={handleClose3}>Profile</MenuItem>
                  </Link>
                  <Link
                    to="/edit-profile"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    <MenuItem onClick={handleClose3}>Edit Profile</MenuItem>
                  </Link>
                </Menu>
                {/*  */}
              </Box>
            )}
            {getDrawerChoices()}
            <Box style={{ display: "flex", flexDirection: "start" }}>
              {!account && (
                <Button
                  className={bgbtn1}
                  // variant="contained"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  // to="/login"
                  // component={Link}
                  onClick={user.connectWallet}
                >
                  Connect
                </Button>
              )}
            </Box>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: "#197ab3", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: "menuButton1",
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
      >
        <Box
          maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Box>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile">My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user">My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/resell-nft">Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create-nft">Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/notification">Notification</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/search">Search</Link>
        </MenuItem>
      </Menu>
    </>
  );
}

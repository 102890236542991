import React, { createContext, useState, useEffect } from "react";
import { injected } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import apiConfig from "src/config";
import axios from "axios";
import Web3 from "web3";
import { ACTIVE_NETWORK, swichNetworkHandler } from "src/constants";
import { toast } from "react-toastify";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

const setTokenSession = (token) => {
  if (token) {
    sessionStorage.setItem("token", token);
  } else {
    sessionStorage.removeItem("token");
  }
};

export default function AuthProvider(props) {
  const { activate, account, library, chainId, deactivate } = useWeb3React();
  const [isLogin, setIsLogin] = useState(false);
  const [userData, setUserData] = useState({});
  const [yourWalletBalance, setYourWalletBalance] = useState(0);
  const [dashBoardData, setDashBoardData] = useState([]);
const [displayMsg, setDisplayMsg] = useState(false);
const connectWalletAPICall = async () => {
  try {
    const res = await axios.post(apiConfig.connectWallet, {
      walletAddress: account,
    });
    if (res.data.statusCode === 200) {
      setTokenSession(res.data.result.token);
      getProfileDetailsHandler();
      setIsLogin(true);
    } else {
      // toast.error(res.data.responseMessage);
      setTokenSession();
      setIsLogin(false);
    }
  } catch (error) {
    setTokenSession();
    setIsLogin(false);
    console.log("ERROR", error);
    // toast.error(error.message);
  }
};

const getUserbalce = async () => {
  var web3 = new Web3(library.provider);
  const balance = await web3.eth.getBalance(account);
  const balanceImETH = await web3.utils.fromWei(balance);
  setYourWalletBalance(parseFloat(balanceImETH).toFixed(4));
};

useEffect(() => {
  if (account) {
    getUserbalce();
  }
}, [account, library]);

useEffect(() => {
  if (account) {
    connectWalletAPICall();
  }
}, [account]);

useEffect(() => {
  if (account && chainId) {
    if (chainId != ACTIVE_NETWORK) {
      swichNetworkHandler(ACTIVE_NETWORK);
    }
  }
}, [account, chainId]);

const getProfileDetailsHandler = async () => {
  try {
    const res = await axios.get(apiConfig.profile, {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    });
    console.log("res", res);
    if (res.data.statusCode === 200) {
      console.log("hello", res.data.result);
      setUserData(res.data.result);
      console.log("userdata------------>", res.data.result);
    } else {
      setUserData({});
    }
  } catch (error) {
    setUserData({});
  }
};

const disconnectWallte = async () => {
  deactivate();
  setIsLogin(false);
  setTokenSession();
};

let data = {
  isLogin,
  userData,
  yourWalletBalance,
  displayMsg,
  getProfileDetailsHandler: () => getProfileDetailsHandler(),
  updateUser: (account) => {
    setSession(account);
  },
  connectWallet: () => {
    setDisplayMsg(true);
    activate(injected, undefined, true)
      .then(() => {
        setDisplayMsg(false);
      })
      .catch((error) => {
        if (error) {
          toast.error(error.message);
          activate(injected);
          setDisplayMsg(false);
        }
      });
  },
  dicconectWalletFun: () => {
    disconnectWallte();
  },
};

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
      console.log("hghjg", userAddress);
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}

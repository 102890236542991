import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiFormLabel: {
      root: { color: "#222" },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "600 !important",
        lineHeight: "33px !important",
      },
    },

    MuiOutlinedInput: {
      root: {
        border: "1px solid #52565c",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiPaper: {
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: "auto",
        paddingLeft: "15px",
        fontSize: "16px",
        textDecoration: "none !important",
        "& a": {
          textDecoration: "none !important",
        },
        "& span": {
          fontSize: "16px",
          textDecoration: "none !important",
        },
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
      },
      paperWidthSm: {
        maxWidth: "900px !important",
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        color: "#222",
        color: "#fff",
        height: "0.1876em",
        padding: "15px 0 15px",
      },
    },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      label: {
        fontFamily: "Cabin",
      },
      containedSecondary: {
        transition: "all 0.2s ease, visibility 0s",
        padding: "20px 30px",
        borderRadius: "50px",
        boxShadow: " none",
        border: "0px solid rgb(63, 118, 82)",
        background:
          " no-repeat padding-box border-box 50%/cover scroll url(./images/bluebtn.png), rgba(63, 118, 82, 0)",
        transition: "inherit",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: " 18px",
        minWidth: "200px",
        backgroundColor: "#89898900",
        "&:hover": {
          boxShadow: " none",
          background:
            " no-repeat padding-box border-box 50%/cover scroll url(./images/btnorange.png), rgba(63, 118, 82, 0)",
          backgroundColor: "#89898900",
        },
      },
      containedSizeSmall: {
        height: "53px",
        width: "141px",
        minWidth: "40px",
        fontSize: "11px",
      },
      containedSizeLarge: {
        padding: "20px 30px",
      },
      contained: {
        borderRadius: "50px",

        // backgroundColor: "transparent",
      },
      containedPrimary: {
        transition: "all 0.2s ease, visibility 0s",
        padding: "20px 30px",
        borderRadius: "50px",
        boxShadow: " none",
        border: "0px solid rgb(63, 118, 82)",
        background:
          " no-repeat padding-box border-box 50%/cover scroll url(./images/btnorange.png), rgba(63, 118, 82, 0)",
        transition: "inherit",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: " 15px",
        minWidth: "172px",
        backgroundColor: "#89898900",
        "&:hover": {
          boxShadow: " none",
          background:
            " no-repeat padding-box border-box 50%/cover scroll url(./images/bluebtn.png), rgba(63, 118, 82, 0)",
          backgroundColor: "#89898900",
        },
      },
      // contained: {
      //   borderRadius: "50px",
      //   color: "#FFFFFF",
      //   fontWeight: 600,
      //   padding: "10px 20px",
      //   // fontFamily: "Cabin !important",
      //   backgroundColor: "#fff",
      //   "&:hover": {
      //     backgroundColor: "#e0e0e0",

      //     color: "#fff",
      //   },
      // },
      outlinedPrimary: {
        borderRadius: "50px",
        color: "#300760",
        fontWeight: 600,
        padding: "5px 19px",
        border: "2px solid #ff7c3a",
        "&:hover": {
          backgroundColor: "#EB4D00",
          border: "2px solid #EB4D00",
          color: "#fff",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },

    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },
    MuiIconButton: {
      root: {
        color: "#ff8140",
      },
    },
    MuiMenu: {
      paper: { top: "47px" },
    },

    MuiTypography: {
      subtitle1: {
        color: "#000",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
